import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import { SyntheticEvent } from 'react';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import { RolesContext } from 'src/Roles/RolesContext';
import { getFirstPathOn401RoleChange } from 'src/Roles/RolesService';
import IAlert from 'src/ServerEntities/IAlert';
import IHybridView from 'src/ServerEntities/IHybridView';
import IRoleSite from 'src/ServerEntities/IRoleSite';
import IServer from 'src/ServerEntities/IServer';
import IServerAlerts from 'src/ServerEntities/IServerAlerts';
import ISite from 'src/ServerEntities/ISite';
import ISitePermissions from 'src/ServerEntities/ISitePermissions';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInput from 'src/SharedComponents/TextInput';
import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import { renderSiteName } from 'src/UsefulFunctions/renderSiteName';
import Alert from 'src/Views/Ram/Components/Alert';
import TypeHolder from 'src/Views/Ram/Components/TypeHolder';
import ServerItem from 'src/Views/Ram/ServerItem';
import { SessionContext } from 'src/Views/SessionContext';
import HeaderLink from './Components/HeaderLink';
import { getSiteHybridView, getSites, updateSiteDescription } from './ramService';
import { filterAndSortUserAlerts } from './Utils/alertUtils';


const MainContent = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    @media (min-width: 1279px) {
        padding: 48px 48px 104px;
    }
    @media (max-width: 1279px) {
        margin-bottom: 120px;
    }
`;

const SiteSelect = styled.select`
    width: 200px;
    padding: 6px 12px;
    border: 1px solid #ffffff;
    background: no-repeat 90% 90%/10% url('/images/drop-arrow.png') #000000;
    color: #ffffff;
    border-radius: 16px;
    font-size: 1.2rem;
    appearance: none;
    cursor: pointer;
    outline: none;
    margin-right: 24px;
`;

const SiteInformationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-bottom: 24px;
    @media (max-width: 1279px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 4px;
        margin-bottom: 4px;
        & a {
            margin-top: 4px;
        }
    }
`;

const SiteContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 100%;
    @media (max-width: 1279px) {
        flex-direction: column;
        flex: 1 1 auto;
    }
`;

const ServersContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 1279px) {
        flex: 1 1 75%;
    }
`;

const AlertsContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 1279px) {
        flex: 1 1 25%;
    }
`;

const SectionTitle = styled.h2`
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0 0 4px;
    @media (min-width: 1279px) {
        margin: 0 0 16px;
    }
`;

const SiteRow = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    & li {
        background-color: #42a83f;
    }
    & li.with-alerts {
        background-color: #f99600;
    }
    & li.without-heartbeat {
        background-color: #ff2121;
    }
`;

const ServerList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
`;

interface IProps {
    selectedTypes: string[],
    viewUserIssues: boolean,
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void,
    sortByDate: boolean
};


const alertToItem = (siteName: string, isSmall: boolean) => (alert: IAlert, index: number) => {
    return <Alert
        alert={alert}
        closed={false}
        key={`site-${siteName}-alert-${index}`}
        minimised={!isSmall}
    />;
};

const Sites = (props: IProps) => {
    const { siteName } = useParams<"siteName">();
    const { selectedTypes, viewUserIssues, changeRole, setChangeRole, sortByDate } = props;
    const { loggedInUser, webToken } = React.useContext(SessionContext).state;
    const [siteNames, setSiteNames] = React.useState([] as unknown as IRoleSite[]);
    const [sites, setSites] = React.useState([] as unknown as ISite[]);
    const [selectedSite, setSelectedSite] = React.useState(siteName || "");
    const [currentAlerts, setCurrentAlerts] = React.useState([] as unknown as IServerAlerts[]);
    const [closedAlerts, setClosedAlerts] = React.useState([] as unknown as IServerAlerts[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [siteDescription, setSiteDescription] = React.useState("");
    const [editing, setEditing] = React.useState(false);
    const [editable, setEditable] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);
    const roleContext = React.useContext(RolesContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery({
        query: '(max-width: 1279px)'
    });

    const siteSorter = (a: IRoleSite, b: IRoleSite) => {
        const nameA: string = a.alias ? a.alias : a.id;
        const nameB: string = b.alias ? b.alias : b.id;
        return nameA.localeCompare(nameB);
    };

    const serverToRow = (siteName: string, permissions: ISitePermissions) => (server: IServer, index: number) => {
        return <ServerItem sitePermissions={permissions} enlarge={false} startExpanded={true} displaySite={false} linkToServer={true} site={siteName}
            siteName={siteName} server={server} key={`site-${siteName}-server-${server.name}-${index}`} refresh={refresh} setRefresh={setRefresh} />;
    };

    const siteToRow = (site: ISite, index: number) => {
        return <SiteRow key={`site-view-ram-site-${index}`}>
            <TypeHolder className={`site-type-${site.type.toLocaleLowerCase()}`}>{site.type}</TypeHolder>
            <ServerList>
                {site.servers.map(serverToRow(site.site, site.permissions))}
            </ServerList>
        </SiteRow>;
    };

    const checkRoleChange = async (errorMessage: string) => {
        if (changeRole) {
            const path = await getFirstPathOn401RoleChange(errorMessage, webToken, roleContext.state.ramConfiguration?.components, roleContext.state.role)
            if (path) {
                navigate(path);
            }
            setChangeRole(false);
        }
    }

    useEffectOnSome(() => {
        setLoading(true);
        getSites(webToken, (serverSites: IRoleSite[]) => {
            serverSites.sort(siteSorter);
            setSiteNames(serverSites);
            setEditable(siteNames.length > 0 && siteNames[0].editable ? true : false);
            if (!selectedSite && serverSites.length) {
                setSelectedSite(serverSites[0].id);
                setSiteDescription(serverSites[0].description);
            }
            setLoading(false);
            setError("");
        }, (errorMessage: string) => {
            setSiteNames([]);
            setLoading(false);
            setError("");
        })
    }, [webToken], [selectedSite]);

    React.useEffect(() => {
        if (selectedSite) {
            setLoading(true);
            getSiteHybridView(selectedSite, webToken, (serverView: IHybridView) => {
                setLoading(false);
                setCurrentAlerts(serverView.alerts);
                setClosedAlerts(serverView.closedAlerts);
                setSites(serverView.sites);
                setError("");
                setChangeRole(false);
            }, (errorMessage: string) => {
                checkRoleChange(errorMessage);
                setLoading(false);
                setError(errorMessage);
                setCurrentAlerts([]);
                setClosedAlerts([]);
                setSites([]);
            });
        }
    }, [selectedSite, webToken, refresh]);

    const changeSelectedSite = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSite(event.target.value);
        const siteSelected = siteNames.find(site => site.id === event.target.value);
        if (siteSelected) {
            setSiteDescription(siteSelected?.description);
        }
    };

    const changeSiteDescription = (event: SyntheticEvent<HTMLInputElement, Event>) => {
        setSiteDescription(event.currentTarget.value);
    };

    const updateDescription = () => {
        setLoading(true);
        updateSiteDescription(selectedSite, siteDescription, webToken, (response: string) => {
            setLoading(false);
            setEditing(false);
            setError("");
        }, (errorMessage: string) => {
            const httpStatusCode = errorMessage.match(/\d{3}/);
            if (httpStatusCode) {
                if (httpStatusCode[0] === '401' && props.changeRole) {
                    props.setChangeRole(false);
                    navigate(`/Ram/Overview`);
                }
            }
            setLoading(false);
            setError(errorMessage);
        });
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key;
        if (key === "Enter") {
            updateDescription();
        }
    };

    const allowEditing = () => {
        if (editable) {
            setEditing(true);
        }
    };

    const filteredAlerts = currentAlerts.filter(alert => selectedTypes.length === 0 || selectedTypes.includes(alert.type));
    const reducedAlerts = filteredAlerts.reduce((previousArray, serverAlert) => previousArray.concat(serverAlert.alerts), [] as IAlert[]);
    const userAlerts = filterAndSortUserAlerts(currentAlerts, loggedInUser, sortByDate).reduce((previousArray, serverAlert) => previousArray.concat(serverAlert.alerts), [] as IAlert[]);
    const filteredSites = sites.filter(site => selectedTypes.length === 0 || selectedTypes.includes(site.type));

    const filteredClosedAlerts = closedAlerts.filter(alert => selectedTypes.length === 0 || selectedTypes.includes(alert.type));
    const reducedClosedAlerts = filteredClosedAlerts.reduce((previousArray, serverAlert) => previousArray.concat(serverAlert.alerts), [] as IAlert[]);
    const userClosedAlerts = filterAndSortUserAlerts(closedAlerts, loggedInUser, sortByDate).reduce((previousArray, serverAlert) => previousArray.concat(serverAlert.alerts), [] as IAlert[]);

    return <MainContent>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{createErrorMessage("loading sites information", error)}</ErrorBox>}
        {siteNames && siteNames.length > 0 && <SiteInformationContainer>
            <SiteSelect onChange={changeSelectedSite} value={selectedSite}>
                {siteNames.map(site => <option key={`site-select-option-${site.id}`} value={site.id}>{renderSiteName(site.id, site.alias)}</option>)}
            </SiteSelect>
            {!siteDescription && !editing && editable && <HeaderLink onClick={allowEditing}>Add description</HeaderLink>}
            {editing && editable ?
                <TextInput width={isSmall ? "280px" : "500px"} maxLength={500} value={siteDescription} onChange={changeSiteDescription} onKeyDown={handleKeyDown} onBlur={updateDescription} /> :
                (siteDescription && <p onClick={allowEditing}>{siteDescription}</p>)
            }
        </SiteInformationContainer>}
        <SiteContainer>
            <ServersContainer>
                <SectionTitle>Servers</SectionTitle>
                <ul>
                    {filteredSites.map(siteToRow)}
                </ul>
            </ServersContainer>
            <AlertsContainer>
                <SectionTitle>Alerts</SectionTitle>
                <ul>
                    {viewUserIssues ? userAlerts.map(alertToItem(selectedSite, isSmall)) : reducedAlerts.map(alertToItem(selectedSite, isSmall))}
                </ul>
                <SectionTitle style={{ marginTop: "50px" }}>Closed alerts</SectionTitle>
                <ul>
                    {viewUserIssues ? userClosedAlerts.map(alertToItem(selectedSite, isSmall)) : reducedClosedAlerts.map(alertToItem(selectedSite, isSmall))}
                </ul>
            </AlertsContainer>
        </SiteContainer>
    </MainContent >;
};

export default Sites; 