import * as React from "react";
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerBanner from 'src/Views/Manager/Components/ManagerBanner';
import RolesTab from 'src/Views/Manager/UserManagement/RolesTab';
import UsersTab from 'src/Views/Manager/UserManagement/UsersTab';
import RoleChangeDialog from 'src/Views/RoleChangeDialog';
import AlertsTab from "./AlertsTab";
import ClientsTab from "./ClientsTab";
import CommonProblemsTab from "./CommonProblemsTab";
import SitesTab from "./SitesTab";
import SiteTypesTab from "./SiteTypesTab";
import SMSNotificationsTab from "./SMSNotificationsTab";
import ThresholdsTab from "./ThresholdsTab";

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChannelCodesTab from "./ChannelCodesTab";
import MutedServersTab from "./MutedServersTab";

interface IProps {
    componentNames: string[],
    pageName?: string
};

const NavContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 22px;
    border-bottom: 1px solid #e5e5e5;
    z-index: 1;

    .right-nav-arrow,
    .left-nav-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 100;
        height: 50px;
        background-color: white;
        width: 6%;
        font-size: 18px;
    }

    .right-nav-arrow {
        padding: 0 10px 0 10px;
        left: 90%;
    }

    .left-nav-arrow {
        padding: 0 10px 0 10px;
        right: 90%;
    }
    
`;

const NavLink = styled.a`
    margin: 12px 12px;
    font-size: 14px;
    color: #575757;
    padding: 4px 12px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    white-space: nowrap;

    &.selected {
        color: #ffffff;
        background: #187fba;
        border-radius: 16px;
        display: flex;
        white-space: nowrap;
        overflow: visible;
        text-overflow: unset; 
    }
`;

const UserManagementIndex = (props: IProps) => {
    const componentNames = props.componentNames;
    const pageName = props.pageName || "Users";
    const [roleChangeDialogOpen, setRoleChangeDialogOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);
    const roleDialogRef = React.useRef(null as unknown as HTMLDivElement);
    const navigate = useNavigate();
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const [hideRightNavArrow, setHideRightNavArrow] = React.useState(false);
    const [hideLeftNavArrow, setHideLeftNavArrow] = React.useState(false);

    const closeRoleChangeDialog = () => {
        setRoleChangeDialogOpen(false);
    };

    const changePage = (pageName: string) => () => {
        navigate(`/Manager/${pageName}`);
    };

    const getComponentForPageName = (pageName: string) => {
        switch (pageName) {
            case "Roles":
                return <RolesTab tablabel="Roles" />;
            case "Clients":
                return <ClientsTab tablabel="Clients" />;
            case "CommonProblems":
                return <CommonProblemsTab tablabel="Common problems" />;
            case "Sites":
                return <SitesTab tablabel="Sites" refreshSiteTypes={refresh} />;
            case "SiteTypes":
                return <SiteTypesTab tablabel="Site types" refreshSiteTypes={refresh} setRefreshSiteTypes={setRefresh} />;
            case "MutedServers":
                return <MutedServersTab tablabel="Muted servers" />;
            case "Notifications":
                return <SMSNotificationsTab tablabel="SMS and email notifications" refresh={refresh} />;
            case "Thresholds":
                return <ThresholdsTab tablabel="Thresholds" refresh={refresh} />;
            case "Alerts":
                return <AlertsTab tablabel="Alerts" refresh={refresh} />;
            case "ChannelCodes":
                return <ChannelCodesTab tablabel="Channel Short Codes" refresh={refresh} />;
            case "Users":
            default:
                return <UsersTab tablabel="Users" outerRefresh={refresh} setOuterRefresh={setRefresh} />;
        }
    };

    const getNavLinkForComponentName = (componentName: string) => {
        switch (componentName) {
            case "Roles":
                return <NavLink key="nav-link-roles" onClick={changePage("Roles")} className={pageName === "Roles" ? "selected" : "deselected"}>Roles</NavLink>;
            case "Clients":
                return <NavLink key="nav-link-clients" onClick={changePage("Clients")} className={pageName === "Clients" ? "selected" : "deselected"}>Clients</NavLink>;
            case "Common problems":
                return <NavLink key="nav-link-common-problems" onClick={changePage("CommonProblems")} className={pageName === "CommonProblems" ? "selected" : "deselected"}>Common problems</NavLink>;
            case "Sites":
                return <NavLink key="nav-link-sites" onClick={changePage("Sites")} className={pageName === "Sites" ? "selected" : "deselected"}>Sites</NavLink>;
            case "Site types":
                return <NavLink key="nav-link-site-types" onClick={changePage("SiteTypes")} className={pageName === "SiteTypes" ? "selected" : "deselected"}>Site types</NavLink>;
            case "MutedServers":
                return <NavLink key="nav-link-muted-servers" onClick={changePage("MutedServers")} className={pageName === "MutedServers" ? "selected" : "deselected"}>Muted servers</NavLink>;
            case "SMS and email notifications":
                return <NavLink key="nav-link-notifications" onClick={changePage("Notifications")} className={pageName === "Notifications" ? "selected" : "deselected"}>SMS and email notifications</NavLink>;
            case "Thresholds":
                return <NavLink key="nav-link-thresholds" onClick={changePage("Thresholds")} className={pageName === "Thresholds" ? "selected" : "deselected"}>Thresholds</NavLink>;
            case "Alerts":
                return <NavLink key="nav-link-alerts" onClick={changePage("Alerts")} className={pageName === "Alerts" ? "selected" : "deselected"}>Alerts</NavLink>;
            case "ChannelCodes":
                return <NavLink key="nav-link-channel-codes" onClick={changePage("ChannelCodes")} className={pageName === "ChannelCodes" ? "selected" : "deselected"}>Channel Short Codes</NavLink>;
            case "Users":
            default:
                return <NavLink key="nav-link-users" onClick={changePage("Users")} className={pageName === "Users" ? "selected" : "deselected"}>Users</NavLink>;
        }
    };

    const handleResize = () => {
        if (scrollRef.current) {
            const containerWidth = scrollRef.current.offsetWidth;
            const contentWidth = scrollRef.current.scrollWidth;
            const scrollPosition = scrollRef.current.scrollLeft;

            if (scrollPosition === 0) {
                setHideLeftNavArrow(true);
                setHideRightNavArrow(contentWidth <= containerWidth);
            } else if ((containerWidth + scrollPosition) >= contentWidth) {
                setHideLeftNavArrow(false);
                setHideRightNavArrow(true);
            } else {
                setHideLeftNavArrow(false);
                setHideRightNavArrow(false);
            }
        }
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize()
    }, [])

    const moveNavRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += 100;

            if (scrollRef.current.scrollLeft === (scrollRef.current.scrollWidth - scrollRef.current.clientWidth)) {
                setHideRightNavArrow(true);
            } else {
                setHideRightNavArrow(false);
            }

            if (scrollRef.current.scrollLeft > 0) {
                setHideLeftNavArrow(false);
            } else {
                setHideLeftNavArrow(true);
            }
        }
    };

    const moveNavLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += -100;

            if (scrollRef.current.scrollLeft === (scrollRef.current.scrollWidth - scrollRef.current.clientWidth)) {
                setHideRightNavArrow(true);
            } else {
                setHideRightNavArrow(false);
            }

            if (scrollRef.current.scrollLeft === 0) {
                setHideLeftNavArrow(true);
            } else {
                setHideLeftNavArrow(false);
            }
        };
    };

    return <ViewContainer>
        <RoleChangeDialog dialogRef={roleDialogRef} open={roleChangeDialogOpen} onClose={closeRoleChangeDialog} applicationName="Manager" darkBackground={true} />
        <ViewContainer>
            <ManagerBanner setRoleChangeDialogOpen={setRoleChangeDialogOpen} />
            <NavContainer ref={scrollRef}>
                {!hideLeftNavArrow && <div className="left-nav-arrow" onClick={moveNavLeft}>
                    <FontAwesomeIcon className='faCog' icon={faArrowLeft} />
                </div>}
                {componentNames.map(getNavLinkForComponentName)}
                {!hideRightNavArrow && <div className="right-nav-arrow" onClick={moveNavRight}>
                    <FontAwesomeIcon className='faCog' icon={faArrowRight} />
                </div>}
            </NavContainer>
            {getComponentForPageName(pageName)}
        </ViewContainer>
    </ViewContainer>;
};

export default UserManagementIndex;
